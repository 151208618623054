import { Modal, message } from 'antd'
import { IntlManager } from 'utils/helper'
import { GetTenantTrainerModuleList, GetCanArrangeTrainerList, PostArrangeTrainer, GetTrainerList, PutTrainerRemark } from 'services/trainingSystem'

const defaultPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
}

const arrangeContentMenu = (intl) => {
    return {
        Properties: [
            {
                EditorType: 'MultipleSingleWithSearch',
                Value: null,
                Setting: {
                    DropdownOptions: [],
                    Required: true,
                    Disabled: false,
                },
                ShowTitle: intl("selectPeople"),
                FormKey: 'trainerId',
                Description: null,
            },
            // {
            //     EditorType: 'Dropdown',
            //     Value: '0',
            //     Setting: {
            //         DropdownOptions: [
            //             {
            //                 Value: intl("centerPayment"),
            //                 Id: '0',
            //             },
            //             {
            //                 Value: intl("privatePayment"),
            //                 Id: '1',
            //             }
            //         ],
            //         Required: true,
            //     },
            //     ShowTitle: intl("arrangeMethod"),
            //     FormKey: 'useNum',
            //     Description: intl("selfPaymentStudents"),
            // },
            // {
            //     EditorType: 'NumberInput',
            //     ShowTitle: intl("paymentAmount"),
            //     FormKey: 'money',
            //     AdditionalData: null,
            //     Value: null,
            //     Setting: {
            //         Required: true,
            //         Disabled: true,
            //         NumberSetting: {
            //             min: 1,
            //             step: 1,
            //             precision: 2,
            //         },
            //     },
            //     Description: null,
            // }
        ]
    }
}

const remarkContentMenu = (intl) => {
    return {
        Properties: [
            {
                EditorType: 'Input',
                Value: null,
                Setting: {
                    Required: true,
                },
                ShowTitle: intl("comments"),
                FormKey: 'remark',
                Description: null,
            },
        ]
    }
}

export default {
    namespace: 'trainingStaff',
    state: {
        targetKeys: [],
        selectedKeys: [],
        tabsData: [],
        list: [],
        trainingStaffData: null,
        activeKeyId: '',
        name: '',
        learningState: '',
        qualifications: '',
        TrainerFrom: '',
        TrainingPayWay: '',
        currentItem: null,
        selectModalVisible: false,
        remarkModalVisible: false,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        content: null,
        remarkContent: null,
        isPutAway: false,
        learningStatus: [],
        issuedStatus: []
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        }
    },

    effects: {

        *query({ payload }, { call, put, select }) {
            yield put({ type: 'getModuleList' })
        },

        *getModuleList({ payload }, { call, put, select }) {
            const { currentUser } = yield select(state => state.user)
            const { activeKeyId } = yield select(state => state.trainingStaff)
            const { id } = currentUser
            const res = yield call(GetTenantTrainerModuleList, { TenantId: id })
            if (res.response.ok) {
                const newActiveKeyId = activeKeyId || (res.data && res.data.length > 0 ? res.data[0].trainingModule && res.data[0].trainingModule.id : '')
                yield put({ type: 'updateState', payload: { tabsData: res.data, activeKeyId: newActiveKeyId } })
                if (res.data && res.data.length > 0) {
                    yield put({ type: 'getTrainerList' })
                }
            }
        },

        *getTrainerList({ payload }, { call, put, select }) {
            const { activeKeyId, name, learningState, qualifications, pagination, TrainerFrom, TrainingPayWay, learningStatus, issuedStatus } = yield select(state => state.trainingStaff)
            const { current, pageSize } = pagination
            const params = {
                Name: name,
                // Issued: qualifications,
                // Status: learningState,
                TrainingModuleId: activeKeyId,
                SkipCount: (current - 1) * pageSize,
                MaxResultCount: pageSize,
                TrainerFrom,
                TrainingPayWay,
                TrainerTrainingModuleStatuses: learningStatus,
                CertificateStatuses: issuedStatus
            }
            const res = yield call(GetTrainerList, params)
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { list: res.data.items, pagination: { ...pagination, total: res.data.totalCount } } })
            }
        },

        *arrangeTraining({ payload }, { call, put, select }) {
            const intl = IntlManager.MyIntl()

            const { type, record, currentData } = payload || {}
            const { trainingModuleId, price } = currentData || {}
            const { id, name, email } = record || {}
            const content = { ...arrangeContentMenu(intl) }
            let res = null
            switch (type) {
                case "arrange":
                    res = yield call(GetCanArrangeTrainerList, { ModuleId: trainingModuleId })
                    if (res.response.ok) {
                        const { canUsePrice } = res.data || {}
                        content.Properties[0].Setting.Disabled = false
                        content.Properties[0].Setting.DropdownOptions = res.data && res.data.trainers && res.data.trainers.map(item => {
                            return {
                                Value: item.name || item.email,
                                Id: item.id,
                                ShowLabel: item.name || item.email,
                            }
                        })
                        content.Properties[0].Value = ""
                        // content.Properties[2].Value = price || 0
                        // if (canUsePrice) {
                        //     content.Properties[1].Setting.DropdownOptions[1].Disabled = false
                        // } else {
                        //     content.Properties[1].Setting.DropdownOptions[1].Disabled = true
                        // }
                        yield put({
                            type: 'updateState',
                            payload: {
                                selectModalVisible: true,
                                trainingStaffData: res.data,
                                content
                            }
                        })
                    }
                    break
                case "rearrange":
                    res = yield call(GetCanArrangeTrainerList, { ModuleId: trainingModuleId })
                    if (res.response.ok) {
                        content.Properties[0].Setting.Disabled = true
                        content.Properties[0].Setting.DropdownOptions = [
                            {
                                Value: name || email,
                                Id: id
                            }
                        ]
                        content.Properties[0].Value = id
                        content.Properties[2].Value = price || 0
                        yield put({
                            type: 'updateState',
                            payload: {
                                selectModalVisible: true,
                                trainingStaffData: res.data,
                                content
                            }
                        })
                    }
                    break
            }

        },

        *editRemark({ payload }, { call, put, select }) {
            const { remark } = payload || {};
            const intl = IntlManager.MyIntl()
            var content = { ...remarkContentMenu(intl) }

            content.Properties[0].Value = remark

            yield put({ type: 'updateState', payload: { remarkModalVisible: true, remarkContent: content, currentItem: payload } })
        },

        *editRemarkSubmit({ payload }, { call, put, select }) {
            const { currentItem } = yield select(state => state.trainingStaff);
            const { id } = currentItem || {};

            const res = yield call(PutTrainerRemark, { ...payload }, { id });

            if (res.response.ok) {
                yield put({ type: 'query' })
            } else {
                const { error } = res.data
                const { message } = error || {}
                message.error(message);
            }

            yield put({ type: 'updateState', payload: { remarkModalVisible: false } })
        },

        *onChangeTabs({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { activeKeyId: payload } })
            yield put({ type: 'query' })
        },

        *submitArrange({ payload }, { call, put, select }) {
            const { activeKeyId, tabsData } = yield select(state => state.trainingStaff)
            const currentData = tabsData.find(item => item.trainingModule && item.trainingModule.id === activeKeyId)
            const { trainingModuleId, numberOfPlaces } = currentData || {}
            const { trainerId, useNum } = payload || {}
            const newUseNum = useNum === '0'
            const params = {
                trainerIds: trainerId,
                trainingModuleId,
                trainerId: '',
                useNum: newUseNum
            }
            const res = yield call(PostArrangeTrainer, params)
            if (res.response.ok) {
                yield put({ type: 'query' })
                yield put({ type: 'updateState', payload: { selectModalVisible: false } })
            } else {
                const { error } = res.data
                const { message } = error || {}
                Modal.error({
                    title: message
                });
            }
        },

        *changeTable({ payload }, { call, put, select }) {
            const { pagination, filters = {} } = payload

            const isActiveFilter = Object.keys(filters).length > 0
            const currentPagination = isActiveFilter ? defaultPagination : pagination

            if (isActiveFilter) {
                const { issuedStatus = [], learningState = [] } = filters
                yield put({
                    type: 'updateState',
                    payload: {
                        issuedStatus,
                        learningStatus: learningState
                    }
                })
            }

            yield put({
                type: 'updateState',
                payload: {
                    pagination: currentPagination
                }
            })
            yield put({ type: 'query' })
        },

        *onsearch({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'query' })
        },

        *onclear({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({
                type: 'updateState',
                payload: {
                    pagination,
                    name: '',
                    learningState: '',
                    qualifications: '',
                    TrainerFrom: '',
                    TrainingPayWay: '',
                    issuedStatus: [],
                    learningStatus: []
                }
            })
            yield put({ type: 'query' })
        }
    },
    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/center/menu/manage/trainingStaff') {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
