import React, { useState, useEffect } from 'react';
import { useIntl, getLocale, setLocale } from 'umi';
import { Button, Spin, Space } from 'antd';
import DocumentTitle from 'react-document-title';
import { showMenu, hideMenu, ContextMenu } from 'utils/customMenu.js';

import {
  GetUserOfflinePlanForPunch,
  GetUserOfflinePlanForNumPunch,
  GetBrainRecord,
  GetCategoryPunchWith,
  GetGroupCategoryPunchWith,
} from 'services/rocketService/UserOfflinePlan';
import { GetListenRecord, GetFeedBack, GetChildFeedback } from 'services/group';
import { CenterGetSubjectInfo } from 'services/userManager';
import { GetTrainerDetail } from 'services/trainingSystem';
import { SetLanguage } from 'services/language';

import {
  tableHeadTitle as tableHeadTitleFunc,
  tableContentHeadTitle as tableContentHeadTitleFunc,
  tablePsychologyHeadTitle as tablePsychologyHeadTitleFunc,
} from './config';

import {
  convertTimeToSecond,
  getBlobData,
} from 'cognitiveleap-core-us/utils/utils';

import { showAttributeName } from 'utils/feedback';
import { convertDuration, fixedZero } from 'utils/utils';

import triangleImg from './images/triangle.png';
import pentagonalImg from './images/pentagonal.png';
import circularImg from './images/circular.png';
import diamondImg from './images/diamond.png';
import rectangleImg from './images/rectangle.png';
import feedtriangleImg from './images/feedtriangle.png';
import feedpentagonalImg from './images/feedpentagonal.png';
import feedcircularImg from './images/feedcircular.png';
import feeddiamondImg from './images/feeddiamond.png';
import feedrectangleImg from './images/feedrectangle.png';

import styles from './index.less';
import Comments from './comments';

import moment from 'moment';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es',
};

const HfsThreeTrainingRecord = ({ location }) => {
  const intl = useIntl();

  const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

  const tableHeadTitle = tableHeadTitleFunc();
  const tableContentHeadTitle = tableContentHeadTitleFunc();
  const tablePsychologyHeadTitle = tablePsychologyHeadTitleFunc();

  const {
    type = 'download',
    from = 'oneToone',
    CognitiveleapUser,
    userTenantId,
    tenantId,
    culture = 'zh-CN',
    userPlanId,
    num,
    subjectId,
    trainerId,
    groupTrainingPlanId,
  } = location.query || {};

  const [renderData, setRenderData] = useState({});
  const [loading, setLoading] = useState({
    downLoading: false,
  });

  const oneToOneGetData = async () => {
    const resList = await Promise.all([
      GetUserOfflinePlanForPunch({ UserOfflinePlanId: userPlanId, num }),
      CenterGetSubjectInfo(subjectId),
      GetUserOfflinePlanForNumPunch({
        UserOfflinePlanId: userPlanId,
        Num: num,
        ForGuardian: true,
      }),
      GetBrainRecord({ UserOfflineTrainingPlanId: userPlanId }),
      GetTrainerDetail({ trainerId }),
      GetCategoryPunchWith({ UserOfflinePlanId: userPlanId, num }),
    ]);

    if (resList.every((item) => item.response.ok)) {
      const [
        planInfoRes,
        subjectInfoRes,
        guardianInfoRes,
        musicInfoRes,
        trainerInfoRes,
        categoryInfoRes,
      ] = resList;

      setRenderData({
        planInfo: planInfoRes.data,
        subjectInfo: subjectInfoRes.data,
        guardianInfo: guardianInfoRes.data,
        musicInfo: musicInfoRes.data,
        trainerInfo: trainerInfoRes.data,
        categoryInfo: categoryInfoRes.data.filter(
          (item) => item.feedbackProperties.length > 0,
        ),
      });
    } else {
      message.error(intl.formatMessage({ id: 'getDataFail' }));
    }
  };

  const oneToMoreGetData = async () => {
    const resList = await Promise.all([
      GetFeedBack({ GroupTrainingPlanId: groupTrainingPlanId, CourseNum: num }),
      CenterGetSubjectInfo(subjectId),
      GetChildFeedback({
        GroupTrainingPlanId: groupTrainingPlanId,
        CourseNum: num,
        SubjectId: subjectId,
      }),
      GetListenRecord({
        UserId: subjectId,
        GroupTrainingPlanId: groupTrainingPlanId,
        Num: num,
      }),
      GetTrainerDetail({ trainerId }),
      GetGroupCategoryPunchWith({
        GroupTrainingPlanId: groupTrainingPlanId,
        CourseNum: num,
        SubjectId: subjectId,
      }),
    ]);

    if (resList.every((item) => item.response.ok)) {
      const [
        planInfoRes,
        subjectInfoRes,
        childInfoRes,
        musicInfoRes,
        trainerInfoRes,
        categoryInfoRes,
      ] = resList;

      const { groupTrainingPlanCourse } = planInfoRes.data;
      const iLs = musicInfoRes.data.map((item) => {
        return item.code + ' ' + fixedZero(item.musicCourseNum);
      });

      const {
        nonePlaybook,
        courseItems,
        coachName,
        hasDone,
        feedbacks,
        endTime,
        feedbackProperties,
      } = groupTrainingPlanCourse;

      const planData = {
        nonePlaybook,
        planItems: courseItems,
        coachName,
        num,
        userOfflineTrainingPlan: {
          currentNum: num,
        },
        iLs,
      };

      const guardianInfo = {
        feedbacks,
        hasDone,
        endTime,
        feedbackProperties,
      };

      const musicInfo = {
        numProgress: musicInfoRes.data,
      };

      setRenderData({
        planInfo: planData,
        subjectInfo: subjectInfoRes.data,
        childInfo: childInfoRes.data[0],
        guardianInfo,
        musicInfo,
        trainerInfo: trainerInfoRes.data,
        categoryInfo: categoryInfoRes.data.filter(
          (item) => item.feedbackProperties.length > 0,
        ),
      });
    } else {
      message.error(intl.formatMessage({ id: 'getDataFail' }));
    }
  };

  useEffect(async () => {
    if (getLocale() !== culture) {
      const res = await SetLanguage({ culture: LangOptions[culture] });
      if (res.response.ok) {
        setLocale(culture);
      }
    }

    if (CognitiveleapUser && userTenantId) {
      localStorage.setItem('CognitiveleapUser', CognitiveleapUser);
      localStorage.setItem('userTenantId', userTenantId);
      if (tenantId) localStorage.setItem('tenantId', tenantId);
    }

    if (from === 'oneToone') {
      oneToOneGetData();
    } else {
      oneToMoreGetData();
    }
  }, []);

  const onClickDownLoad = () => {
    document.getElementById('downlaodButton').style.display = 'none';
    window.print();
    document.getElementById('downlaodButton').style.display = 'block';
  };

  const onDownFeedback = async () => {
    setLoading({
      ...loading,
      downLoading: true,
    });
    const url =
      language == 'zh'
        ? 'https://rocketsystem.s3.us-west-1.amazonaws.com/Course/Medias/SessionFeedbackZh.pdf'
        : 'https://rocketsystem.s3.us-west-1.amazonaws.com/Course/Medias/SessionFeedbackEn.pdf';

    getBlobData(url, (href) => {
      const elink = document.createElement('a'); //创建个a链接元素
      elink.href = href;
      elink.style.display = 'none';
      elink.setAttribute(
        'download',
        `${intl.formatMessage({ id: 'Session Feedback Instructions' })}.pdf`,
      );
      document.body.appendChild(elink);
      elink.click(); //触发点击方法
      URL.revokeObjectURL(elink.href); // 释放URL对象
      document.body.removeChild(elink); //释放节点

      setLoading({
        ...loading,
        downLoading: false,
      });
    });
  };

  const initCustomMenu = () => {
    const contextMenu = ContextMenu({
      menus: [
        {
          name: intl.formatMessage({ id: 'Instructions' }),
          onClick: () => onDownFeedback(),
        },
        {
          name: intl.formatMessage({ id: 'printTimetable' }),
          onClick: () => {
            hideMenu(_, contextMenu);
            setTimeout(() => {
              onClickDownLoad();
            }, 100);
          },
        },
        {
          name: intl.formatMessage({ id: 'backTop' }),
          onClick: () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          },
        },
      ].filter(item => item),
    });
    document.addEventListener('contextmenu', (event) =>
      showMenu(event, contextMenu),
    );
    document.addEventListener('click', (event) => hideMenu(event, contextMenu));
  };

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => event.preventDefault());
    initCustomMenu();
    // 禁用浏览器快捷键打印功能
    window.addEventListener('keydown', function (e) {
      if (e.ctrlKey && (e.key === 'p' || e.key === 'P')) {
        e.preventDefault();
        onClickDownLoad();
      }
    });
  }, [])

  if (Object.keys(renderData).length === 0) {
    return <Spin />;
  }

  const {
    planInfo,
    subjectInfo,
    guardianInfo,
    childInfo,
    musicInfo,
    trainerInfo,
    categoryInfo,
  } = renderData;

  const {
    isApp = false,
    coachName,
    num: myNum,
    userOfflineTrainingPlan,
    planItems = [],
    nonePlaybook = [],
  } = planInfo || {};
  const { name: userName } = subjectInfo || {};
  const { numProgress } = musicInfo || {};
  const { name: trainName } = trainerInfo || {};
  const {
    feedbackProperties: childFeedbackProperties = [],
    feedbacks: childFeedbacks = [],
  } = childInfo || {};
  const {
    feedbacks: guardianFeedbacks = [],
    hasDone: guardianHasDone,
    feedbackProperties: guardianFeedbackProperties = [],
  } = guardianInfo || {};

  let nonePlaybookData = nonePlaybook || [];
  const { currentNum } = userOfflineTrainingPlan || {};

  // 给静态活动加上标志
  nonePlaybookData = nonePlaybookData.map((item) => {
    return {
      ...item,
      isStaticPlaybook: true,
    };
  });

  const showPlanItemData = planItems.concat(nonePlaybookData);

  const showCategoryData = categoryInfo.concat(
    nonePlaybookData.map((item) => {
      const {
        feedbacks = [],
        feedbackProperties = [],
        trainingProgram,
      } = item || {};
      const { title, id } = trainingProgram || {};
      return {
        category: {
          displayName: title,
          id,
        },
        feedbackProperties: feedbackProperties.filter(
          (feedItem) => feedItem.name === 'attentionOnFlow',
        ),
        feedbacks,
        isStaticPlaybook: true,
      };
    }),
  );

  let trainerName = myNum === currentNum ? trainName || '' : coachName;

  // 获取聆听时长
  let currentMusicInfo =
    from === 'oneToone'
      ? (numProgress &&
          numProgress.find((item) => item.num === parseInt(num))) ||
        null
      : (numProgress && numProgress[0]) || null;
  let webProgress = 0;

  if (currentMusicInfo) {
    if (from === 'oneToone') {
      if (isApp) {
        currentMusicInfo.appCodeProgress.forEach((item) => {
          webProgress += convertTimeToSecond(item.progress);
        });
      } else {
        currentMusicInfo.webCodeProgress.forEach((item) => {
          webProgress += convertTimeToSecond(item.progress);
        });
      }
    } else {
      currentMusicInfo.appCodeProgress.forEach((item) => {
        webProgress += convertTimeToSecond(item.progress);
      });
      currentMusicInfo.webCodeProgress.forEach((item) => {
        webProgress += convertTimeToSecond(item.progress);
      });
    }
  }

  let behaviourValue; // 本次训练总体表现
  let transitionalBehaviorValue; // 活动之间的过度转换

  // 显示整体反馈内容
  if (guardianInfo) {
    if (guardianHasDone === true) {
      if (guardianFeedbacks.length > 0) {
        guardianFeedbacks[0].feedbackProperties.forEach((item) => {
          const { name, type, value } = item || {};

          switch (name) {
            case 'courseTime':
              tableHeadTitle[3].name =
                tableHeadTitle[3].name +
                (value ? moment(value).format('YYYY/MM/DD') : '');
              break;
            case 'behaviour':
              behaviourValue = value;
              break;
            case 'transitionalBehavior':
              transitionalBehaviorValue = showAttributeName(
                type,
                value,
                item,
                '; ',
              );
              break;
            case 'emotionalState': // 情绪状态
            case 'behavioralActivity': // 行为活动
            case 'focusAttention': // 专注力和注意力
            case 'socialInteraction': // 社交互动
            case 'selfRegulation': // 自我调节
            case 'communicationStyle': // 沟通方式
            case 'motivationLevel': // 动机/内驱力
              const res = showAttributeName(type, value, item, '; ');

              const keyIndex = tablePsychologyHeadTitle.findIndex(
                (item) => item?.key === name || item?.twoKey === name,
              );

              if (keyIndex !== -1) {
                const { key } = tablePsychologyHeadTitle[keyIndex];
                tablePsychologyHeadTitle[keyIndex].haveData = true;
                if (key === name) {
                  tablePsychologyHeadTitle[keyIndex].valueText = res;
                } else {
                  tablePsychologyHeadTitle[keyIndex].twoValueText = res;
                }
              }

              break;
          }
        });
      }
    }

    if (guardianHasDone === false) {
      const { endTime, doneTime } = guardianInfo;
      const tempTime = endTime || doneTime;
      tableHeadTitle[3].name =
        tableHeadTitle[3].name +
        (tempTime ? moment(tempTime).format('YYYY/MM/DD') : '');
    }
  }

  // 显示班级 个人反馈
  if (childFeedbacks && childFeedbacks.length > 0) {
    childFeedbacks[0].feedbackProperties.forEach((item) => {
      const { name, type, value } = item || {};
      switch (name) {
        case 'behaviour':
          behaviourValue = value;
          break;
        case 'transitionalBehavior':
          transitionalBehaviorValue = showAttributeName(
            type,
            value,
            item,
            '; ',
          );
          break;
        case 'emotionalState': // 情绪状态
        case 'behavioralActivity': // 行为活动
        case 'focusAttention': // 专注力和注意力
        case 'socialInteraction': // 社交互动
        case 'selfRegulation': // 自我调节
        case 'communicationStyle': // 沟通方式
        case 'motivationLevel': // 动机/内驱力
          const res = showAttributeName(type, value, item, '; ');

          const keyIndex = tablePsychologyHeadTitle.findIndex(
            (item) => item?.key === name || item?.twoKey === name,
          );

          if (keyIndex !== -1) {
            const { key } = tablePsychologyHeadTitle[keyIndex];
            tablePsychologyHeadTitle[keyIndex].haveData = true;
            if (key === name) {
              tablePsychologyHeadTitle[keyIndex].valueText = res;
            } else {
              tablePsychologyHeadTitle[keyIndex].twoValueText = res;
            }
          }

          break;
      }
    });
  }

  // 给表头赋值
  tableHeadTitle[0].name = tableHeadTitle[0].name + userName;
  tableHeadTitle[1].name = tableHeadTitle[1].name + num;
  tableHeadTitle[2].name =
    tableHeadTitle[2].name +
    (webProgress > 0 ? convertDuration(webProgress) : '');
  tableHeadTitle[4].name = tableHeadTitle[4].name + trainerName;

  const getImage = (configData) => {
    const { imageType, imageCount, isFeed, mockCount = 0 } = configData || {};

    let tempImageCount = imageCount || 0;

    if (
      imageType == 'confidenceLevel' ||
      imageType == 'activityCompletion' ||
      imageType == 'adherenceToStandards'
    ) {
      if (imageCount == 0) {
        tempImageCount = 1;
      } else if (imageCount == 0.5) {
        tempImageCount = 2;
      } else if (imageCount == 1) {
        tempImageCount = 3;
      }
    } else if (imageType == 'enjoymentLevel') {
      // 1, 2, 3, 4
      tempImageCount = parseInt(imageCount);
    } else {
      // 0, 1, 2
      tempImageCount = parseInt(imageCount) + 1;
    }

    let mockArrayData = [];
    const arrayData = new Array(tempImageCount).fill(0);

    if (mockCount && mockCount - tempImageCount > 0) {
      mockArrayData = new Array(mockCount - tempImageCount).fill(0);
    }

    let url = '';
    let width = 11;
    switch (imageType) {
      case 'activityCompletion': // 完成度
        url = isFeed ? feedtriangleImg : triangleImg;
        break;
      case 'adherenceToStandards': // 标准性
        url = isFeed ? feedcircularImg : circularImg;
        break;
      case 'confidenceLevel': // 信心
        url = isFeed ? feeddiamondImg : diamondImg;
        break;
      case 'enjoymentLevel': //喜爱程度
        url = isFeed ? feedrectangleImg : rectangleImg;
        break;
      default:
        url = isFeed ? feedpentagonalImg : pentagonalImg;
        break;
    }

    return (
      <React.Fragment>
        {arrayData.map((item, index) => {
          return (
            <img
              src={url}
              key={index}
              width={width}
              style={{ marginRight: '2px' }}
            />
          );
        })}
        {mockArrayData.map((item, index) => {
          return (
            <img
              src={pentagonalImg}
              key={index}
              width={width}
              style={{ marginRight: '2px' }}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const showData = (data, configData, index) => {
    const { propertyName } = configData || {};
    let item = '';
    let dataRes = '';
    switch (propertyName) {
      case 'index':
        return index + 1;
      case 'planname':
        if (data.isStaticPlaybook) {
          if (data.hasDone === false) {
            return '------';
          }
          if (data.feedbacks && data.feedbacks.length > 0) {
            const flowTypeData = data.feedbacks[0].feedbackProperties.find(
              (item) => item.name === 'flowType',
            );

            const { type, value } = flowTypeData || {};

            return showAttributeName(type, value, flowTypeData, '; ');
          }

          return '';
        } else {
          const planname1 =
            data &&
            data.trainingProgram &&
            `${data.trainingProgram.title || ''} ${
              data.trainingProgram.difficulty || ''
            }`;
          const planname2 =
            data.feedbacks && data.feedbacks.length > 0
              ? data.feedbacks[0].feedbackProperties.find(
                  (item) => item.name === 'name',
                )
              : '';
          return planname2 ? `${planname1}-${planname2['value']}` : planname1;
        }
      case 'categoriesType':
        if (data.isStaticPlaybook) {
          return data && data.trainingProgram && data.trainingProgram.title;
        } else {
          return (
            data &&
            data.trainingProgram &&
            data.trainingProgram.categories &&
            Array.isArray(data.trainingProgram.categories) &&
            data.trainingProgram.categories
              .map((item) => item.displayName)
              .join('、')
          );
        }
      case 'firstRound':
        if (data.hasDone === false) {
          return '------';
        }
        item =
          data.feedbacks && data.feedbacks.length > 0
            ? data.feedbacks[0].feedbackProperties.find(
                (item) =>
                  item.name === 'firstRound' ||
                  item.name === 'continuousAttention',
              )
            : '';
        dataRes = item ? item.value : '';
        return data.isStaticPlaybook ? '------' : dataRes;
      case 'secondRound':
        if (data.hasDone === false) {
          return '------';
        }
        item =
          data.feedbacks && data.feedbacks.length > 0
            ? data.feedbacks[0].feedbackProperties.find(
                (item) =>
                  item.name === 'secondRound' || item.name === 'preBehaviour',
              )
            : '';
        dataRes = item ? item.value : '';
        return data.isStaticPlaybook ? '------' : dataRes;
      case 'thirdRound':
        if (data.hasDone === false) {
          return '------';
        }
        item =
          data.feedbacks && data.feedbacks.length > 0
            ? data.feedbacks[0].feedbackProperties.find(
                (item) =>
                  item.name === 'thirdRound' || item.name === 'bestPerformance',
              )
            : '';
        dataRes = item ? item.value : '';
        return data.isStaticPlaybook ? '------' : dataRes;
      case 'activityCompletion': // 完成度
      case 'adherenceToStandards': // 标准性
      case 'confidenceLevel': // 信心
      case 'enjoymentLevel': //喜爱程度
        if (data.isStaticPlaybook && propertyName === 'adherenceToStandards') {
          return '------';
        }
        if (data.hasDone === false) {
          return '------';
        } else {
          if (data.feedbacks && data.feedbacks.length > 0) {
            item = data.feedbacks[0].feedbackProperties.find(
              (item) => item.name === propertyName,
            );
            dataRes = item ? item.value : 0;
            return getImage({
              imageType: propertyName,
              imageCount: dataRes,
              isFeed: true,
            });
          } else {
            return getImage({
              imageType: propertyName,
              imageCount: propertyName === 'enjoymentLevel' ? 4 : 3,
              isFeed: false,
            });
          }
        }
      default:
        item =
          data.feedbacks && data.feedbacks.length > 0
            ? data.feedbacks[0].feedbackProperties.find(
                (item) => item.name == propertyName,
              )
            : '';
        var { options } = item
          ? item.programFeedbackProperty.extraProperties[language] ??
            itemsItem.programFeedbackProperty.extraProperties
          : [];
        var findItem = options
          ? options.find((e) => e.value === item.value)
          : undefined;
        return findItem !== undefined ? findItem.text : '';
    }
  };

  const getShowTitleAndDescription = (key) => {
    let currentFeedbackProperties = [];
    if (from === 'oneToone') {
      currentFeedbackProperties = guardianFeedbackProperties;
    } else {
      currentFeedbackProperties = childFeedbackProperties;
    }

    if (currentFeedbackProperties.length > 0) {
      const findData =
        currentFeedbackProperties.find((item) => item.name === key) || {};

      if (Object.keys(findData).length > 0) {
        let _displayName;
        const { displayName, extraProperties, name } = findData;

        var { options = [] } = extraProperties[language] ?? extraProperties;

        if (name === 'behavioralActivity' || name === 'communicationStyle') {
          _displayName = displayName + '*';
        } else {
          _displayName = displayName;
        }

        return {
          displayName: _displayName
            .replace('【心理状态】', '')
            .replace('[Mental Statuses]', ''),
          optionsText:
            options.length > 0
              ? options
                  .filter((item) => item.value != 'customizeOthers')
                  .map((item) => item.text)
                  .join('; ')
              : '',
        };
      }
    }

    return { displayName: '', optionsText: '' };
  };

  const transitionalBehaviorData = getShowTitleAndDescription(
    'transitionalBehavior',
  );

  const commentProps = {
    planItems,
    getImage,
  };

  return (
    <DocumentTitle
      title={`${intl.formatMessage({
        id: 'Session Feedback',
      })}-${num}-${userName}`}
    >
      <>
        <div className={styles.hfsThreeTrainingRecord}>
          <table>
            <thead>
              <tr>
                <th
                  colspan="5"
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                >
                  {intl.formatMessage({ id: 'HFSTrainingRecord' })}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {tableHeadTitle.map((item) => {
                  const { name, style } = item || {};
                  return (
                    <td key={name} style={style}>
                      {name}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>

          <table style={{ marginTop: '-1px' }}>
            <thead>
              <tr>
                {tableContentHeadTitle.map((item) => {
                  const { name, style } = item || {};
                  return <th style={style}>{name}</th>;
                })}
              </tr>
            </thead>
            <tbody style={{ textAlign: 'center' }}>
              {showPlanItemData.map((item, index) => {
                const { id } = item || {};

                return (
                  <tr key={id}>
                    {tableContentHeadTitle.map((tableItem) => {
                      const { name, style } = tableItem || {};
                      return (
                        <td key={name} style={style}>
                          {showData(item, tableItem, index)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <table style={{ marginTop: '-1px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>
                  {intl.formatMessage({ id: 'Transitions Between Activities' })}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    color: transitionalBehaviorValue ? '#000000' : '#a5a5a5',
                  }}
                >
                  {transitionalBehaviorValue ||
                    `${
                      transitionalBehaviorData.optionsText
                    }; ${intl.formatMessage({ id: 'other' })}_______`}
                </td>
              </tr>
            </tbody>
          </table>

          <table style={{ marginTop: '-1px' }}>
            <thead>
              <tr>
                <th colspan="4" style={{ textAlign: 'left' }}>
                  {intl.formatMessage({ id: 'Mental Statuses' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {tablePsychologyHeadTitle.map((item, index) => {
                const { key, valueText, twoKey, twoValueText, haveData } = item;

                let title, des, twoDes, twoTitle;

                const isHaveTwo = twoKey;

                const { optionsText, displayName } =
                  getShowTitleAndDescription(key);

                title = displayName;
                des = optionsText;

                if (isHaveTwo) {
                  const {
                    optionsText: twoOptionsText,
                    displayName: twoDisplayName,
                  } = getShowTitleAndDescription(twoKey);

                  twoTitle = twoDisplayName;
                  twoDes = twoOptionsText;
                }

                return (
                  <tr key={index}>
                    <td style={{ width: '120px', fontWeight: 'bold' }}>
                      {title}
                    </td>
                    <td
                      colSpan={isHaveTwo ? 1 : 3}
                      style={{ color: haveData ? '#000000' : '#a5a5a5' }}
                    >
                      {valueText || des}
                    </td>
                    {twoTitle && (
                      <td style={{ width: '120px', fontWeight: 'bold' }}>
                        {twoTitle}
                      </td>
                    )}
                    {(twoValueText || twoDes) && (
                      <td style={{ color: haveData ? '#000000' : '#a5a5a5' }}>
                        {twoValueText || twoDes}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <table style={{ marginTop: '-1px' }}>
            <thead>
              <tr>
                <th colspan="3" style={{ textAlign: 'left' }}>
                  {intl.formatMessage({ id: 'Overall Category Performance' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Evaluation' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Behavior' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <th colspan="3" style={{ textAlign: 'left' }}>
                  {intl.formatMessage({ id: 'Overall Category Performance' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Evaluation' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Behavior' })}
                </th>
              </tr> */}
              {showCategoryData.map((item, index) => {
                const {
                  category,
                  feedbackProperties = [],
                  feedbacks = [],
                } = item || {};
                const { id, displayName } = category || {};

                let feedbackRes = [];
                const firstItem = feedbackProperties[0] || {};
                const { displayName: firstDisplayName, name: firstName } =
                  firstItem || {};

                if (feedbacks.length > 0) {
                  feedbackRes = feedbacks[0].feedbackProperties;
                }

                const firstFeedbackRes = feedbackRes.filter(
                  (resItem) => resItem.name === firstName,
                );

                const otherFeedbackRes = feedbackRes.filter(
                  (resItem) => resItem.name !== firstName,
                );

                return (
                  <>
                    <tr key={id}>
                      <th scope="row" rowSpan={feedbackProperties.length}>
                        {index + 1}
                      </th>
                      <th scope="row" rowSpan={feedbackProperties.length}>
                        {displayName}
                      </th>
                      <td>{firstDisplayName}</td>
                      {firstFeedbackRes.length > 0 ? (
                        firstFeedbackRes.map((resItem) => {
                          const { value, type, name } = resItem || {};
                          return (
                            <>
                              <td
                                style={{ width: '85px', textAlign: 'center' }}
                              >
                                {type === 'Selection' &&
                                  getImage({
                                    imageType: name,
                                    imageCount: parseInt(value),
                                    isFeed: true,
                                    mockCount: 3,
                                  })}
                              </td>
                              <td
                                style={{ width: '85px', textAlign: 'center' }}
                              >
                                {showAttributeName(type, value, resItem, '; ')}
                              </td>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <td style={{ width: '85px', textAlign: 'center' }}>
                            {getImage({
                              imageType: firstName,
                              imageCount: 2,
                              isFeed: false,
                            })}
                          </td>
                          <td
                            style={{ width: '85px', textAlign: 'center' }}
                          ></td>
                        </>
                      )}
                    </tr>
                    {feedbackProperties.length > 1 &&
                      feedbackProperties.slice(1).map((feedbackItem) => {
                        const { displayName, id, name } = feedbackItem || {};
                        return (
                          <tr key={id}>
                            <td>{displayName}</td>
                            {otherFeedbackRes.length > 0 ? (
                              otherFeedbackRes.map((resItem) => {
                                const { value, type, name } = resItem || {};

                                return (
                                  <>
                                    <td
                                      style={{
                                        width: '85px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {type === 'Selection' &&
                                        getImage({
                                          imageType: name,
                                          imageCount: parseInt(value),
                                          isFeed: true,
                                          mockCount: 3,
                                        })}
                                    </td>
                                    <td
                                      style={{
                                        width: '85px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {showAttributeName(
                                        type,
                                        value,
                                        resItem,
                                        '; ',
                                      )}
                                    </td>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <td
                                  style={{ width: '85px', textAlign: 'center' }}
                                >
                                  {getImage({
                                    imageType: name,
                                    imageCount: 2,
                                    isFeed: false,
                                  })}
                                </td>
                                <td
                                  style={{ width: '85px', textAlign: 'center' }}
                                ></td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                  </>
                );
              })}
            </tbody>
          </table>

          <table style={{ marginTop: '-1px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>
                  {intl.formatMessage({ id: 'performanceTraining' })}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '25px' }}>
                <td>{behaviourValue}</td>
              </tr>
            </tbody>
          </table>

          <table style={{ marginTop: '-1px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }} colspan="2">
                  {intl.formatMessage({ id: 'note' })}
                </th>
              </tr>
            </thead>
            <tbody>
              <Comments {...commentProps} />
            </tbody>
          </table>
        </div>

        {type !== 'download' && (
          <div className={styles.downlaod}>
            <div id="downlaodButton">
              <Space>
                <Button onClick={onDownFeedback} loading={loading.downLoading}>
                  {intl.formatMessage({ id: 'Instructions' })}
                </Button>
                <Button onClick={onClickDownLoad}>
                  {intl.formatMessage({ id: 'printTimetable' })}
                </Button>
              </Space>
              <p />
            </div>
          </div>
        )}
      </>
    </DocumentTitle>
  );
};

export default HfsThreeTrainingRecord;
