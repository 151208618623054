import React, { useState, useRef, useEffect } from 'react';
import {
  message,
  Steps,
  Card,
  Form,
  Select,
  Input,
  Button,
  Modal,
  Space,
  Radio,
  DatePicker,
  Image,
  Tabs,
  AutoComplete,
  Empty,
  Tooltip,
} from 'antd';
import { useIntl, getLocale, history, connect } from 'umi';
import { getMobilePrefix, downloadQr } from 'utils/utils';
import {
  isEffectualPhoneNumber,
  eventDebounce,
} from 'cognitiveleap-core-us/utils/utils';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import ConfirmModal from '../AddUser/ConfirmModal';
import CustomNameInput from 'components/CustomNameInput/CustomNameInputTwo';

import {
  PrepareSubject,
  CreateUserAndSubject,
  CheckSubject,
  GetCenterUserList,
  AddSubject,
  GetReferralInfoQuestion,
} from 'services/userManager';
import { AddVratTest, GetCheckGuardian } from 'services/vrat';
import { GetCaseCode } from 'services/vrat/testCenter';
import { GetEnoughVratNum } from 'services/vrat';
import { GetGrant } from 'services/account';

import styles from './index.less';

const { Step } = Steps;
const { Option } = Select;

const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

let jumpInterval;

const VratProcess = ({ currentUser, location }) => {
  const { current, auth } = currentUser || {};
  const { grantedPolicies } = auth;
  const { type = 'addVart' } = location.query;

  const inputRef = useRef(null);

  const hasAssignCoachPermission =
    grantedPolicies['Business.SubjectManagement.UserCoach.Assign'] === true;

  const isAddVrat = type === 'addVart';

  const currentTenantId = current.id;
  const currentUserId = current.userId;

  const intl = useIntl();
  const isZh = getLocale() === 'zh-CN';

  const [currentStep, setCurrentStep] = useState(0);
  const [editChildData, setEditChildData] = useState({});
  const [initCode, setInitCode] = useState(isZh ? '+86' : '+1');
  const [currentSubject, setCurrentSubject] = useState({});
  const [guardianInfo, setGuardianInfo] = useState({});
  const [options, setOptions] = useState([]);
  const [existedParentData, setExistedParentData] = useState({});
  const [referralInfoQuestions, setReferralInfoQuestions] = useState([]);
  const [souseSelectOpen, setSouseSelectOpen] = useState(false);
  const [souseSelectValue, setSouseSelectValue] = useState('');
  const [customizeOthersInput, setCustomizeOthersInput] = useState('');
  const [loading, setLoading] = useState({
    checkPhoneLoading: false,
    submitButtonLoading: false,
    registeredUserLoading: false,
  });
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const [checkPhoneForm] = Form.useForm();
  const [addVratForm] = Form.useForm();
  const [registeredUserForm] = Form.useForm();

  const mobilePrefix = getMobilePrefix(intl);

  const { avaliableGuardianRoles = [], coaches = [] } = editChildData;

  const hasExistedParentData = Boolean(
    Object.keys(existedParentData || {}).length,
  );

  const prefixSelector = (
    <Form.Item
      name={'countryCode'}
      initialValue={initCode}
      style={{ marginBottom: 0 }}
    >
      <Select style={{ width: 150 }} onChange={(value) => setInitCode(value)}>
        {mobilePrefix.map((item, key) => {
          const { prefix, countryName } = item;
          return (
            <Option
              value={prefix}
              key={key}
            >{`${countryName}(${prefix})`}</Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const validateLimit = (rule, value) => {
    if (value === undefined || value.length === 0) {
      return Promise.reject();
    }
    if (!isEffectualPhoneNumber(initCode, value)) {
      return Promise.reject(intl.formatMessage({ id: 'phoneNumError' }));
    } else {
      return Promise.resolve();
    }
  };

  const nextStep = async () => {
    if (jumpInterval) {
      Modal.destroyAll();
      clearInterval(jumpInterval);
    }

    setLoading({
      ...loading,
      checkPhoneLoading: true,
      registeredUserLoading: true,
    });

    const resList = await Promise.all([
      GetReferralInfoQuestion(),
      PrepareSubject(),
    ]);

    if (resList.every((item) => item.response.ok)) {
      const [firstRes, twoRes] = resList;
      const { extraProperties } = firstRes.data || {};
      const { options } = extraProperties[language] ?? extraProperties;

      setEditChildData(twoRes.data);
      setReferralInfoQuestions(options);
      setCurrentStep((currentStep) => currentStep + 1);
    }

    setLoading({
      ...loading,
      registeredUserLoading: false,
      checkPhoneLoading: false,
    });
  };

  const showGoGuardianModal = (phone, findTenant) => {
    setExistedParentData(findTenant);

    // 3s自动跳转
    jumpInterval = setInterval(() => {
      nextStep();
    }, 3 * 1000);

    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: intl.formatMessage({ id: 'existingUserFlow' }, { phone }),
      okText: intl.formatMessage({ id: 'submit' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      onCancel: () => {
        if (jumpInterval) clearInterval(jumpInterval);
      },
      onOk: () => {
        nextStep();
        // history.replace({
        //   pathname: '/system/center/menu/user/user',
        //   query: {
        //     p: phone,
        //   },
        // });
      },
    });
  };

  const checkPhoneNum = async (value) => {
    setLoading({
      ...loading,
      checkPhoneLoading: true,
    });
    const { phone, countryCode } = value;

    const res = await GetCheckGuardian({
      RegisterType: 'Phone',
      Phone: phone,
      CountryCode: countryCode,
    });

    if (res.response.ok) {
      const tenantList = res.data;

      if (tenantList.length > 0) {
        // 本中心已注册
        const findTenant =
          tenantList.find((item) => item.tenantId === currentTenantId) || {};
        if (Object.keys(findTenant).length > 0) {
          // 本中心本账号
          if (current.roles.some((role) => role.name === 'Coach')) {
            // 本账号
            if (
              tenantList.some((item) =>
                item.subjects.some((subject) =>
                  subject.coach
                    .filter((coach) => coach.state === 'Effective')
                    .some((coach) => coach.id === currentUserId),
                ),
              )
            ) {
              showGoGuardianModal(phone, findTenant);
            } else {
              // 非本账号
              const coachList = [];
              findTenant.subjects.forEach((subject) => {
                const { coach } = subject;
                const activeCoach = coach.filter(
                  (coach) => coach.state === 'Effective',
                );
                if (activeCoach.length > 0) {
                  coachList.push(activeCoach[0].name);
                }
              });

              Modal.warning({
                title: intl.formatMessage(
                  { id: 'coachModalTitle' },
                  {
                    coach:
                      coachList.length === 0
                        ? intl.formatMessage({ id: 'centerAdmin' })
                        : coachList.join('，'),
                  },
                ),
                content: intl.formatMessage({ id: 'coachModalContent' }),
              });
            }
          } else {
            // 本账号
            showGoGuardianModal(phone, findTenant);
          }
        } else {
          // 非本中心已注册
          const res = await GetGrant(current.id);
          if (res.response.ok) {
            const qrCodeUrl = `data:image/png;base64,${res.data}`;
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              title: intl.formatMessage({ id: 'anotherCenterTitle' }),
              okText: intl.formatMessage({ id: 'iknow' }),
              cancelText: intl.formatMessage({ id: 'downloadQRCodeOther' }),
              content: (
                <div>
                  <div>
                    {intl.formatMessage({ id: 'anotherCenterContent' })}
                  </div>
                  <div className={styles.qrCode}>
                    <Image src={qrCodeUrl} width={100} />
                    <div className={styles.qrCodeInfo}>
                      <div>{intl.formatMessage({ id: 'WechatScan' })}</div>
                      <div>{intl.formatMessage({ id: 'anthorizeCenter' })}</div>
                    </div>
                  </div>
                </div>
              ),
              onCancel: () => {
                downloadQr({
                  type: 'base64',
                  data: qrCodeUrl,
                  title: intl.formatMessage({ id: 'WechatScan' }),
                  subTitle: intl.formatMessage({ id: 'anthorizeCenter' }),
                  description: current.displayName,
                  downloadName: intl.formatMessage({
                    id: 'QRCodeofThisCenterWeChat',
                  }),
                  imgType: 'examCode',
                });
              },
            });
          }
        }
      } else {
        // 未注册手机号
        setExistedParentData({});
        nextStep();
      }
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    // setLoading({
    //   ...loading,
    //   checkPhoneLoading: false,
    // });
  };

  const registeredUserSubmit = () => {
    if (hasExistedParentData) {
      nextStep();
    }
  };

  const runError = () => {
    setCurrentStep(0);
  };

  const getSourceText = (source) => {
    if (source === 'customizeOthers') {
      const params = {
        customizeOthersText: customizeOthersInput,
        checkValues: source,
      };
      return JSON.stringify(params);
    }
    return source;
  };

  const addVratFormFinish = async () => {
    let guardianId, phone, countryCode;

    setLoading({
      ...loading,
      submitButtonLoading: true,
    });

    if (hasExistedParentData) {
      guardianId = existedParentData.id;
      phone = existedParentData.phone;
      countryCode = existedParentData.countryCode;
    } else {
      phone = checkPhoneForm.getFieldValue('phone');
      countryCode = checkPhoneForm.getFieldValue('countryCode');
    }

    const {
      birthDay,
      childGender,
      childFullName,
      gradeName,
      language,
      fullName,
      assignTrainers,
      coachIds,
      guardianRole,
      email,
      city,
      source,
      emergencyContactName,
      emergencyContactPhoneNumber,
      emergencyContactRole,
    } = addVratForm.getFieldsValue(true);

    const { firstName = '', lastName = '' } = childFullName || {};

    const hasEnoughVratNumRes = await GetEnoughVratNum();
    if (hasEnoughVratNumRes.response.ok) {
      if (hasEnoughVratNumRes.data) {
        Modal.confirm({
          title: null,
          icon: null,
          okText: intl.formatMessage({ id: 'add' }),
          cancelText: intl.formatMessage({ id: 'cancel' }),
          content: intl.formatMessage(
            { id: 'assignVratTest' },
            { name: firstName + lastName }, // TODO
          ),
          onOk: async () => {
            const firstApiParams = {
              subjects: [
                {
                  birthDay: birthDay.format(),
                  fullName: childFullName,
                  gender: childGender,
                  gradeName,
                  language,
                  assignTrainers,
                  coachIds,
                },
              ],
            };

            const addSubjectParams = {
              guardianId,
              ...firstApiParams,
            };

            const createUserAndSubjectParams = {
              fullName,
              email,
              guardianRole,
              city,
              source: getSourceText(source),
              emergencyContactName,
              emergencyContactPhoneNumber,
              emergencyContactRole,
              phoneNumber: phone,
              phone,
              countryCode,
              emergencyContactCountryCode: countryCode,
              ...firstApiParams,
            };

            // 添加小孩和家长信息
            const res = hasExistedParentData
              ? await AddSubject(addSubjectParams)
              : await CreateUserAndSubject(createUserAndSubjectParams);

            if (res.response.ok) {
              let id;
              // 仅添加一个儿童
              if (hasExistedParentData) {
                id = res.data[0]?.id;
              } else {
                const { subjects } = res.data;
                id = subjects[0]?.id;
              }

              // 给小孩添加vrat
              const resTwo = await AddVratTest(id, {});
              if (resTwo.response.ok) {
                const { Id } = resTwo.data;

                // 获取对应的测试码
                const resThree = await GetCaseCode({ testRequestId: Id });
                if (resThree.response.ok) {
                  const { Code } = resThree.data;
                  Modal.info({
                    title: (
                      <div>
                        {intl.formatMessage({ id: 'testCode' })}：{Code}
                      </div>
                    ),
                    okText: intl.formatMessage({ id: 'copy' }),
                    icon: <CheckCircleOutlined style={{ color: '#52C41A' }} />,
                    content: null,
                    onOk() {
                      if (copy(Code)) {
                        message.success(intl.formatMessage({ id: 'success' }));
                        history.replace(
                          '/system/center/menu/statistic/vratList',
                        );
                      } else {
                        message.error(intl.formatMessage({ id: 'failure' }));
                      }
                    },
                  });
                } else {
                  const { error } = resThree.data;
                  message.error(error.message);
                  runError();
                }
              } else {
                const { Error } = resTwo.data;
                message.error(Error.Message);
                runError();
              }
            } else {
              const { error } = res.data;
              message.error(error.message);
              runError();
            }
          },
        });
      } else {
        message.error(intl.formatMessage({ id: 'addVratError' }));
      }
    } else {
      const { error } = hasEnoughVratNumRes.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      submitButtonLoading: false,
    });
  };

  const addChildFormFinish = async () => {
    setLoading({
      ...loading,
      submitButtonLoading: true,
    });

    Modal.confirm({
      title: null,
      icon: null,
      okText: intl.formatMessage({ id: 'yes' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      content: intl.formatMessage({ id: 'submitDes' }),
      onOk: async () => {
        let guardianId, phone, countryCode;

        if (hasExistedParentData) {
          guardianId = existedParentData.id;
          phone = existedParentData.phone;
          countryCode = existedParentData.countryCode;
        } else {
          phone = checkPhoneForm.getFieldValue('phone');
          countryCode = checkPhoneForm.getFieldValue('countryCode');
        }

        const {
          birthDay,
          childGender,
          childFullName,
          gradeName,
          fullName,
          assignTrainers,
          language,
          coachIds,
          email,
          guardianRole,
          city,
          source,
          emergencyContactName,
          emergencyContactPhoneNumber,
          emergencyContactRole,
        } = addVratForm.getFieldsValue(true);

        const firstApiParams = {
          subjects: [
            {
              birthDay: birthDay.format(),
              fullName: childFullName,
              gender: childGender,
              gradeName,
              language,
              assignTrainers,
              coachIds,
            },
          ],
        };

        const addSubjectParams = {
          guardianId,
          ...firstApiParams,
        };

        const createUserAndSubjectParams = {
          fullName,
          email,
          guardianRole,
          city,
          source: getSourceText(source),
          emergencyContactName,
          emergencyContactPhoneNumber,
          emergencyContactRole,
          phoneNumber: phone,
          phone,
          countryCode,
          emergencyContactCountryCode: countryCode,
          ...firstApiParams,
        };

        // 添加小孩和家长信息
        const res = hasExistedParentData
          ? await AddSubject(addSubjectParams)
          : await CreateUserAndSubject(createUserAndSubjectParams);

        if (res.response.ok) {
          message.success(intl.formatMessage({ id: 'success' }));
          history.replace('/system/center/menu/user/subject');
        } else {
          const { error } = res.data;
          message.error(error.message);
          runError();
        }
      },
    });

    setLoading({
      ...loading,
      submitButtonLoading: false,
    });
  };

  const coachOptions = coaches.map((item) => {
    const { id, name, email, isActive, state, roles } = item || {};
    const { displayName } = roles[0] || {};

    const showName = name || intl.formatMessage({ id: 'No Name' });

    if (!isActive) {
      return {
        Value: showName + ' - ' + displayName + ' - ' + email,
        Id: id,
        ShowLabel: showName,
        DisableToolTip: intl.formatMessage({ id: 'AccountNotActivated' }),
      };
    }
    if (state !== 'Effective') {
      return {
        Value: showName + ' - ' + displayName + ' - ' + email,
        Id: id,
        ShowLabel: showName,
        DisableToolTip: intl.formatMessage({ id: 'Invalid Account' }),
      };
    }
    return {
      Value: showName + ' - ' + displayName + ' - ' + email,
      Id: id,
      ShowLabel: showName,
    };
  });

  const confirmModalProps = {
    guardianInfo,
    subjectList: [currentSubject],
    visible: confirmModalVisible,
    onCancel() {
      setConfirmModalVisible(false);
    },
    onOk() {
      isAddVrat ? addVratFormFinish() : addChildFormFinish();
      setConfirmModalVisible(false);
    },
  };

  const checkChild = async (value) => {
    const {
      guardianRole,
      emergencyContactRole,
      coachIds,
      childGender,
      childFullName,
      source,
    } = value;

    const guardianRoleRelationshipLocale = avaliableGuardianRoles.find(
      (item) => {
        return item.name === guardianRole;
      },
    )?.displayName;

    const emergencyContactRoleRelationshipLocale = avaliableGuardianRoles.find(
      (item) => {
        return item.name === emergencyContactRole;
      },
    )?.displayName;

    const sourceText =
      souseSelectValue === 'customizeOthers'
        ? customizeOthersInput
        : referralInfoQuestions.find((item) => {
            return item.value === source;
          })?.text;

    const coachNames =
      coachIds && Array.isArray(coachIds) && coachIds.length > 0
        ? coachIds
            .map(
              (item) => coaches.find((coachItem) => coachItem.id === item).name,
            )
            .join('; ')
        : '';
    setCurrentSubject({
      ...value,
      gender: childGender,
      coachNames,
      fullName: childFullName,
    });

    if (hasExistedParentData) {
      setGuardianInfo({
        ...value,
        guardianRoleRelationshipLocale,
        emergencyContactRoleRelationshipLocale,
        sourceText,
      });
    } else {
      setGuardianInfo({
        ...checkPhoneForm.getFieldsValue(true),
        ...value,
        guardianRoleRelationshipLocale,
        emergencyContactRoleRelationshipLocale,
        sourceText,
      });
    }
    setConfirmModalVisible(true);
  };

  const handleSearch = async (value) => {
    if (!value) {
      setOptions([]);
      return;
    }

    eventDebounce(async () => {
      const res = await GetCenterUserList({
        filter: value,
        maxResultCount: 10,
        skipCount: 0,
      });

      if (res.response.ok) {
        const { items = [] } = res.data;
        setOptions(items.filter((item) => item.name && item.phoneNumber));
      }
    }, 420);
  };

  useEffect(() => {
    if (Object.keys(existedParentData || {}).length > 0) {
      const { source } = existedParentData || {};
      const isSelectCustomizeOthers = source?.includes('customizeOthers');

      if (isSelectCustomizeOthers) {
        const { customizeOthersText, checkValues } = JSON.parse(source);
        setCustomizeOthersInput(customizeOthersText);
        setSouseSelectValue(checkValues);
      }
    }
  }, [existedParentData]);

  const getFormInitData = () => {
    const { role, source } = existedParentData || {};
    const isSelectCustomizeOthers = source?.includes('customizeOthers');
    let _source = source;

    if (isSelectCustomizeOthers) {
      const { checkValues } = JSON.parse(source);
      _source = checkValues;
    }

    return {
      ...existedParentData,
      guardianRole: role,
      source: _source,
    };
  };

  return (
    <div className={styles.panel}>
      <Card
        title={
          <Space align="baseline">
            <Button onClick={() => history.goBack()}>
              {intl.formatMessage({ id: 'back' })}
            </Button>
            <h3>
              {intl.formatMessage({
                id: isAddVrat ? 'subject.newVRATTest' : 'addNewUser',
              })}
            </h3>
          </Space>
        }
        style={{ width: '95%' }}
      >
        <Steps
          current={currentStep}
          className={styles.stepList}
          labelPlacement="vertical"
        >
          <Step
            title={
              <span style={{ fontSize: '14px' }}>
                {intl.formatMessage({
                  id: isAddVrat ? 'checkPhoneNumber' : 'checkParentInfo',
                })}
              </span>
            }
          />
          <Step
            title={
              <span style={{ fontSize: '14px' }}>
                {intl.formatMessage({
                  id: isAddVrat ? 'addAndCode' : 'twoStepAddUser',
                })}
              </span>
            }
          />
        </Steps>
      </Card>

      {currentStep === 0 && (
        <Card className={styles.checkPhoneCard}>
          <Tabs
            defaultActiveKey="1"
            centered
            items={[
              {
                label: intl.formatMessage({ id: 'newUser' }),
                key: 'newUser',
                children: (
                  <Form
                    form={checkPhoneForm}
                    onFinish={checkPhoneNum}
                    className={styles.checkPhoneForm}
                  >
                    <Form.Item
                      label={intl.formatMessage({ id: 'phone' })}
                      name={'phone'}
                      rules={[
                        {
                          required: true,
                          message: `${intl.formatMessage({
                            id: 'phone',
                          })} ${intl.formatMessage({ id: 'isRequired' })}`,
                        },
                        { validator: validateLimit },
                      ]}
                    >
                      <Input
                        style={{ width: '400px' }}
                        addonBefore={prefixSelector}
                        placeholder={intl.formatMessage({ id: 'pleaseEnter' })}
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: '30px' }}
                      loading={loading.checkPhoneLoading}
                    >
                      {intl.formatMessage({ id: 'next' })}
                    </Button>
                  </Form>
                ),
              },
              {
                label: intl.formatMessage({ id: 'registeredUser' }),
                key: 'registeredUser',
                children: (
                  <Form
                    form={registeredUserForm}
                    onFinish={registeredUserSubmit}
                    className={styles.checkPhoneForm}
                  >
                    <Form.Item
                      label={intl.formatMessage({ id: 'guardian' })}
                      name={'guardian'}
                      rules={[
                        {
                          required: true,
                          message: `${intl.formatMessage({
                            id: 'guardian',
                          })} ${intl.formatMessage({ id: 'isRequired' })}`,
                        },
                      ]}
                    >
                      <AutoComplete
                        style={{ width: 400 }}
                        onSearch={handleSearch}
                        placeholder={intl.formatMessage({
                          id: 'searchParentOrPhone',
                        })}
                        onSelect={(value) => {
                          const [_name, _phoneNumber] = value.split('-');
                          const findData =
                            options.find((item) => {
                              const { name, phoneNumber } = item || {};
                              return (
                                phoneNumber.includes(_phoneNumber) &&
                                name === _name
                              );
                            }) || {};
                          setExistedParentData(findData);
                        }}
                        notFoundContent={
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                        options={options.map((item) => {
                          const { name, phoneNumber } = item || {};
                          const simplePhoneNumber = phoneNumber.replace(
                            /\+86|\+1|\+972|\+52|\+34|\+506|\+886|\+852/g,
                            '',
                          );
                          const showText = `${name}-${simplePhoneNumber}`;
                          return {
                            value: showText,
                            label: showText,
                          };
                        })}
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: '30px' }}
                      loading={loading.registeredUserLoading}
                    >
                      {intl.formatMessage({ id: 'next' })}
                    </Button>
                  </Form>
                ),
              },
            ]}
          />
        </Card>
      )}

      {currentStep === 1 && (
        <Form
          form={addVratForm}
          onFinish={checkChild}
          className={styles.addVratForm}
          {...formItemLayout}
          initialValues={hasExistedParentData ? getFormInitData() : {}}
        >
          {/* 家长信息表单 */}
          <Card
            title={intl.formatMessage({ id: 'parentInformation' })}
            className={styles.addVratCard}
          >
            <Form.Item
              label={intl.formatMessage({ id: 'Add Guardian Name' })}
              name={'fullName'}
              rules={
                hasExistedParentData
                  ? []
                  : [
                      {
                        required: true,
                        message: `${intl.formatMessage({
                          id: 'name',
                        })} ${intl.formatMessage({ id: 'isRequired' })}`,
                      },
                    ]
              }
            >
              <CustomNameInput disabled={hasExistedParentData} />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'Add Email Address' })}
              name={'email'}
              rules={
                hasExistedParentData
                  ? []
                  : [
                      {
                        required: true,
                        message: `${intl.formatMessage({
                          id: 'email',
                        })} ${intl.formatMessage({ id: 'isRequired' })}`,
                      },
                      {
                        type: 'email',
                        message: intl.formatMessage({
                          id: 'valid email address',
                        }),
                      },
                    ]
              }
            >
              <Input
                style={{ width: '400px' }}
                placeholder={intl.formatMessage({ id: 'pleaseEnter' })}
                disabled={hasExistedParentData}
              />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'Relationship to Child' })}
              name={'guardianRole'}
              rules={
                hasExistedParentData
                  ? []
                  : [
                      {
                        required: true,
                        message: `${intl.formatMessage({
                          id: 'Relationship to Child',
                        })} ${intl.formatMessage({ id: 'isRequired' })}`,
                      },
                    ]
              }
            >
              <Select
                showSearch={true}
                allowClear={true}
                style={{ width: '400px' }}
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                optionLabelProp="label"
                placeholder={intl.formatMessage({ id: 'pleaseSelect' })}
                disabled={hasExistedParentData}
              >
                {avaliableGuardianRoles.map((item) => {
                  const { displayName, name } = item || {};
                  return (
                    <Option key={name} value={name} label={displayName}>
                      {displayName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'locatedCity' })}
              name={'city'}
            >
              <Input
                style={{ width: '400px' }}
                placeholder={intl.formatMessage({ id: 'pleaseEnter' })}
                disabled={hasExistedParentData}
              />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'Referral Information' })}
              name={'source'}
            >
              <Select
                open={souseSelectOpen}
                showSearch={true}
                allowClear={true}
                style={{ width: '400px' }}
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                optionLabelProp="label"
                placeholder={intl.formatMessage({ id: 'pleaseSelect' })}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    if (souseSelectValue === 'customizeOthers') {
                      setTimeout(() => {
                        inputRef.current?.focus();
                      }, 500);
                    }
                    setSouseSelectOpen(true);
                  }
                }}
                onBlur={() => {
                  if (souseSelectValue !== 'customizeOthers') {
                    setSouseSelectOpen(false);
                  }
                }}
                onSelect={(value) => {
                  setSouseSelectValue(value);

                  if (value == 'customizeOthers') {
                    setTimeout(() => {
                      inputRef.current?.focus();
                    }, 100);
                  } else {
                    setSouseSelectOpen(false);
                  }
                }}
                disabled={hasExistedParentData}
              >
                {referralInfoQuestions.map((item) => {
                  const { text, value } = item || {};
                  const isSelectCustomizeOthers =
                    value == 'customizeOthers' &&
                    souseSelectValue === 'customizeOthers';

                  return (
                    <Option
                      key={value}
                      value={value}
                      label={
                        isSelectCustomizeOthers ? (
                          <Space>
                            {text}
                            <Input
                              size="small"
                              disabled={hasExistedParentData}
                              value={customizeOthersInput}
                            />
                          </Space>
                        ) : (
                          text
                        )
                      }
                    >
                      <Space>
                        {text}
                        {isSelectCustomizeOthers && (
                          <Input
                            size="small"
                            value={customizeOthersInput}
                            ref={inputRef}
                            onPressEnter={() => setSouseSelectOpen(false)}
                            onBlur={() => setSouseSelectOpen(false)}
                            onChange={(e) =>
                              setCustomizeOthersInput(e.target.value)
                            }
                          />
                        )}
                      </Space>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <div
              style={{
                textAlign: 'center',
                width: '100%',
                marginBottom: '20px',
              }}
            >
              —— {intl.formatMessage({ id: 'Contact Person Information' })} ——
            </div>

            <Form.Item
              label={intl.formatMessage({ id: 'name' })}
              name={'emergencyContactName'}
            >
              <Input
                style={{ width: '400px' }}
                placeholder={intl.formatMessage({ id: 'pleaseEnter' })}
                disabled={hasExistedParentData}
              />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'Relationship to Child' })}
              name={'emergencyContactRole'}
            >
              <Select
                showSearch={true}
                allowClear={true}
                style={{ width: '400px' }}
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                optionLabelProp="label"
                placeholder={intl.formatMessage({ id: 'pleaseSelect' })}
                disabled={hasExistedParentData}
              >
                {avaliableGuardianRoles.map((item) => {
                  const { displayName, name } = item || {};
                  return (
                    <Option key={name} value={name} label={displayName}>
                      {displayName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'Add Phone Number' })}
              name={'emergencyContactPhoneNumber'}
            >
              <Input
                style={{ width: '400px' }}
                placeholder={intl.formatMessage({ id: 'pleaseEnter' })}
                disabled={hasExistedParentData}
              />
            </Form.Item>
          </Card>

          {/* 儿童信息表单 */}
          <Card
            title={intl.formatMessage({ id: 'childrenInformation' })}
            className={styles.addVratCard}
          >
            <Form.Item
              label={intl.formatMessage({ id: 'name' })}
              name={'childFullName'}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: 'name',
                  })} ${intl.formatMessage({ id: 'isRequired' })}`,
                },
                {
                  validator: (rule, value, callback) => {
                    if (value && /^\s*$/.test(value)) {
                      callback(
                        `${intl.formatMessage({
                          id: 'name',
                        })} ${intl.formatMessage({ id: 'isRequired' })}`,
                      );
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <CustomNameInput />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'birthday' })}
              name={'birthDay'}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: 'birthday',
                  })} ${intl.formatMessage({ id: 'isRequired' })}`,
                },
              ]}
              help={intl.formatMessage({ id: 'pleaseCheckCarefully' })}
            >
              <DatePicker
                placeholder="mm/dd/yyyy"
                format={'MM/DD/YYYY'}
                style={{ width: '400px' }}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'GenderChoice' })}
              name={'childGender'}
              initialValue={'Male'}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: 'gender',
                  })} ${intl.formatMessage({ id: 'isRequired' })}`,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="Male">{intl.formatMessage({ id: 'male' })}</Radio>
                <Radio value="Female">
                  {intl.formatMessage({ id: 'female' })}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {/* <Form.Item
              label={intl.formatMessage({ id: 'dominantHand' })}
              name={'dominantHand'}
              initialValue={'Right'}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: 'dominantHand',
                  })} ${intl.formatMessage({ id: 'isRequired' })}`,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="LeftHand">
                  {intl.formatMessage({ id: 'left' })}
                </Radio>
                <Radio value="Right">
                  {intl.formatMessage({ id: 'right' })}
                </Radio>
              </Radio.Group>
            </Form.Item> */}

            {/* <Form.Item
              label={intl.formatMessage({ id: 'relationship' })}
              name={'guardianRole'}
              initialValue={'Mother'}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: 'relationship',
                  })} ${intl.formatMessage({ id: 'isRequired' })}`,
                },
              ]}
              help={
                <span style={{ marginTop: '16px', color: '#F96B4B' }}>
                  {intl.formatMessage({ id: 'submitTip' })}
                </span>
              }
            >
              <Radio.Group>
                {avaliableGuardianRoles.map((role) => {
                  const { displayName, name } = role;
                  return (
                    <Radio value={name} key={name}>
                      {displayName}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item> */}

            {/* <Form.Item
              label={intl.formatMessage({ id: 'locatedCity' })}
              name={'city'}
            >
              <Input
                style={{ width: '400px' }}
                placeholder={intl.formatMessage({ id: 'pleaseEnter' })}
              />
            </Form.Item> */}

            <Form.Item
              label={intl.formatMessage({ id: 'grade' })}
              name={'gradeName'}
              initialValue={null}
              // rules={[
              //   {
              //     required: true,
              //     message: `${intl.formatMessage({
              //       id: 'grade',
              //     })} ${intl.formatMessage({ id: 'isRequired' })}`,
              //   },
              // ]}
            >
              <Radio.Group>
                <Radio value="0">
                  {intl.formatMessage({ id: 'kindergarten' })}
                </Radio>
                <Radio value="1">{1}</Radio>
                <Radio value="2">{2}</Radio>
                <Radio value="3">{3}</Radio>
                <Radio value="4">{4}</Radio>
                <Radio value="5">{5}</Radio>
                <Radio value="6">{6}</Radio>
                <Radio value="7">{7}</Radio>
                <Radio value="8">{8}</Radio>
                <Radio value="9">{9}</Radio>
                <Radio value="10">{10}</Radio>
                <Radio value="11">{11}</Radio>
                <Radio value="12">{12}</Radio>
                <Radio value="13">{intl.formatMessage({ id: 'other' })}</Radio>
                <Radio value="14">
                  {intl.formatMessage({ id: 'notAttendingSchool' })}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'Preferred Language' })}
              name={'language'}
              initialValue={null}
            >
              <Radio.Group>
                <Radio value="En">
                  {intl.formatMessage({ id: 'Select English' })}
                </Radio>
                <Radio value="Zh">
                  {intl.formatMessage({ id: 'Chinese Mandarin' })}
                </Radio>
                <Radio value="ZhYue">
                  {intl.formatMessage({ id: 'Chinese Cantonese' })}
                </Radio>
              </Radio.Group>
            </Form.Item>

            {/* <Form.Item
              label={intl.formatMessage({ id: 'schoolNature' })}
              name={'schoolNature'}
              // rules={[
              //   {
              //     required: true,
              //     message: `${intl.formatMessage({
              //       id: 'schoolNature',
              //     })} ${intl.formatMessage({ id: 'isRequired' })}`,
              //   },
              // ]}
            >
              <Radio.Group>
                <Radio value="Public">
                  {intl.formatMessage({ id: 'public' })}
                </Radio>
                <Radio value="Private">
                  {intl.formatMessage({ id: 'private' })}
                </Radio>
                <Radio value="International">
                  {intl.formatMessage({ id: 'international' })}
                </Radio>
                <Radio value="NotGoingToSchool">
                  {intl.formatMessage({ id: 'notAttendingSchool' })}
                </Radio>
              </Radio.Group>
            </Form.Item> */}

            {hasAssignCoachPermission && (
              <Form.Item
                label={intl.formatMessage({ id: 'assignTrainers' })}
                name={'coachIds'}
              >
                <Select
                  mode="multiple"
                  showSearch={true}
                  allowClear={true}
                  style={{ width: '400px' }}
                  filterOption={(input, option) => {
                    if (Array.isArray(option.props.children)) {
                      return option.props?.children?.find((item) =>
                        item.includes(input?.toLowerCase()),
                      );
                    }

                    if (
                      Object.prototype.toString.call(option.props.children) ===
                      '[object Object]'
                    ) {
                      const {
                        props: {
                          children: {
                            props: { children },
                          },
                        },
                      } = option.props.children || {};
                      return (
                        children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                        0
                      );
                    }

                    return (
                      option.props?.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                  optionLabelProp="label"
                  placeholder={intl.formatMessage({ id: 'pleaseSelect' })}
                >
                  {coachOptions.map((item) => {
                    const { DisableToolTip, ShowLabel } = item || {};
                    if (DisableToolTip) {
                      return (
                        <Option
                          value={item.Id}
                          disabled={true}
                          key={item.Id}
                          label={ShowLabel}
                        >
                          <Tooltip placement="top" title={DisableToolTip}>
                            <span
                              style={{ width: '100%', display: 'inline-block' }}
                            >
                              {item.Value}
                            </span>
                          </Tooltip>
                        </Option>
                      );
                    } else {
                      return (
                        <Option key={item.Id} value={item.Id} label={ShowLabel}>
                          {item.Value}
                        </Option>
                      );
                    }
                  })}
                </Select>
              </Form.Item>
            )}
          </Card>

          <Card
            className={styles.addVratCard}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Space>
              <Button
                size="large"
                onClick={() => setCurrentStep((currentStep) => currentStep - 1)}
              >
                {intl.formatMessage({ id: 'previous' })}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading.submitButtonLoading}
              >
                {intl.formatMessage({ id: 'submit' })}
              </Button>
            </Space>
          </Card>
        </Form>
      )}

      <ConfirmModal {...confirmModalProps} />
    </div>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  React.memo(VratProcess),
);
